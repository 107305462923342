<template>
	<div>
		<b-modal
			id="modal-form-delete-resid"
			ref="modal"
			@ok="handleOk"
		>
			<template slot="modal-header" slot-scope="{ close }">
				<h2 class="mx-auto my-auto">{{ $t('modal.main_place.title') }}</h2>
				<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
			</template>

			{{ $t('modal.main_place.get_out.justification') }}

			<template slot="modal-footer" slot-scope="{ ok, cancel }" class="justify-content-center">
				<b-button variant="primary" @click="ok()">
					{{ $t('modal.main_place.goto_getout') }}
				</b-button>
				<b-button @click="cancel()">
					{{ $t('modal.main_place.skip') }}
				</b-button>
        	</template>
		</b-modal>
	</div>
</template>

<script>
    export default {
        props: {
			success_callback: { type: Function, default: () => false }
		},
        methods: {
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
			},
            handleSubmit() {
                this.success_callback()

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$refs.modal.hide()
                })
            },
            openModal() {
                this.$bvModal.show('modal-form-delete-resid')
            }
        }
    }
</script>
